
import LoadingSpinner from '@/components/loading_spinner'
import Geolocator from '@/lib/geolocator'

export default {
  components: { LoadingSpinner }

  mounted: ->
    @fetch_suppliers_for_location() if @supplier_required # must have supplier

  watch: {
    is_visible: ->
      @fetch_suppliers_for_location() if @is_visible and not @editing_order
  }

  data: -> {
    is_visible: false
  }

  computed: {
    browsing_target: ->
      if @$store.getters['application/is_agency']
        if @selected_supplier then @selected_supplier.name else '-'
      else
        if @selected_supplier then @selected_supplier.name else 'Price comparison'

    selected_supplier: -> @$store.getters['suppliers/current']

    suppliers: -> @$store.getters['suppliers/list']

    loading: -> @$store.state.suppliers.loading

    market_application: ->
      !@$store.getters['application/is_agency']

    agency: ->
      @$store.getters['application/is_agency']

    supplier_required: ->
      return false if not @supplier_required_locations.test(@$route.name)
      !@selected_supplier.id and @agency

    supplier_required_locations: ->
      new RegExp(['catalog_root','category','product','promotions','search','stokvel','combos'].join('|'))

    show_supplier_select_handle: ->
      @supplier_required_locations.test(@$route.name)

    is_persistent: ->
      return true if @is_datafree
      @selected_supplier == false

    editing_order: ->
      @$store.getters["cart/is_editing_order"]

    is_datafree: ->
      @$route.query.referer && @$route.query.referer == 'moya'
  }

  methods: {
    choose_supplier: (id) ->
      @$store.dispatch('suppliers/select', id)
      @hide_selector()
      @$router.push('/') if @$router.currentRoute.path != '/'

    toggle_supplier: (id) ->
      if id != @selected_supplier.id then @choose_supplier(id) else @hide_selector()

    fetch_suppliers: (location) ->
      location.distance = 15 if @is_datafree
      @$store.dispatch('suppliers/fetch', location)
      .then (response) =>
        if @supplier_required
          if @suppliers.length == 1
            @$store.dispatch('suppliers/select', @suppliers[0].id)
          else @is_visible = true
      .catch -> console.error

    formatted_distance: (number) ->
      number?.toFixed(1)

    fetch_suppliers_for_location: ->
      new Geolocator(@fetch_suppliers, @fetch_suppliers).locate()

    active_supplier_class: (supplier_id) ->
      'primary primary_text--text' if supplier_id == @selected_supplier.id

    show_selector: ->
      @is_visible = true

    hide_selector: ->
      @is_visible = false
  }

}
